import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="当店について" />
      <p className="text-textblack text-center text-3xl lg:text-4xl self-center pt-5">当店について</p>
    <div className="container space-y-5 px-5 lg:px-0 my-8 justify-center content-center">
      <table className="table-auto mx-auto">
        <tr>
          <td className="border px-4 py-2 text-textblack">
            名称
          </td>
          <td className="border px-4 py-2 text-textblack">
            有限会社アライヤ写真館
          </td>
        </tr>
        <tr>
          <td className="border px-4 py-2 text-textblack">
            所在地
          </td>
          <td className="border px-4 py-2 text-textblack">
            〒361-0077 埼玉県行田市忍1-6-6
          </td>
        </tr>
        <tr>
          <td className="border px-4 py-2 text-textblack">
            電話番号
          </td>
          <td className="border px-4 py-2 text-textblack">
            TEL: 048-556-2921<br />
            FAX: 048-556-1078
          </td>
        </tr>
        <tr>
          <td className="border px-4 py-2 text-textblack">
            メールアドレス
          </td>
          <td className="border px-4 py-2 text-textblack">
            迷惑メール防止のため、公開しておりません。<Link to="/#contact">お電話・お問合せフォーム</Link>よりご連絡ください。
          </td>
        </tr>
        <tr>
          <td className="border px-4 py-2 text-textblack">
            カメラマン・代表
          </td>
          <td className="border px-4 py-2 text-textblack">
            粕谷 明則
          </td>
        </tr>
        <tr>
          <td className="border px-4 py-2 text-textblack">
            営業時間
          </td>
          <td className="border px-4 py-2 text-textblack">
            AM10:00 ~ PM 6:00 <br />
            不定休(出張撮影時、休館)
          </td>
        </tr>
        <tr>
          <td className="border px-4 py-2 text-textblack">
            URL
          </td>
          <td className="border px-4 py-2 text-textblack">
            https://araiya-photo.com
          </td>
        </tr>
      </table>
    </div>
  </Layout>
)

export default AboutPage